import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const singleChatSlice = createSlice({
  name: "singleChat",
  initialState: {
    singleChats: [],
    singleChatDetails: {},
    refetch: false,
  },
  reducers: {
    getSingleChatListSuccess: (state, action) => {
      state.singleChats = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getSingleChatDetailsSuccess: (state, action) => {
      state.singleChatDetails = action.payload;
    },
  },
});

export const {
  getSingleChatListSuccess,
  refetch,
  getSingleChatDetailsSuccess,
} = singleChatSlice.actions;

export const getSingleChats = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/admin/get/single/chat/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getSingleChatListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
   
    setIsLoading(false);
  }
};

export const getSingleChatDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/minigroup/get/member/list/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getSingleChatDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
   
    setLoading(false);
  }
};

export const deleteMiniGroup =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/delete/minigroup/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
     
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const sendAlertToUser = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/send/alert/${id}/v1`;
    const { data } = await axios.post(
      url,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      }
    );
    setLoading(false);
  } catch (error) {
   
    setLoading(false);
  }
};

export const removeMultipleSingleChats =
(selectedIds, setIsLoading) => async (dispatch) => {
  try {
    dispatch(refetch(false));
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/remove/multiple/groupchat/v1`;

    const { data } = await axios.put(
      url,
      { selectedIds },
      {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      }
    );
    setIsLoading(false);
    dispatch(refetch(true));
    toast.success(data.message, {
      position: "top-center",
      autoClose: 3000,
    });
  } catch (error) {
    setIsLoading(false);
    toast.error(error.data.response.message, {
      position: "top-center",
      autoClose: 3000,
    });
  }
};

export const restoreSingleChat =
  (id, setIsLoading, setRestoreModal) => async (dispatch) => {
    try {
      setIsLoading(true)
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/restore/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: localStorage.getItem("vctoken") },
        }
      );
      dispatch(refetch(true));
      setRestoreModal(false);
      setIsLoading(false);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
     
      setIsLoading(false);
      setRestoreModal(false);
    }
  };

export default singleChatSlice.reducer;
