import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const groupSlice = createSlice({
  name: "group",
  initialState: {
    groups: [],
    groupDetails: {},
    marcoPolo: [],
    messages: [],
    refetch: false,
  },
  reducers: {
    getGroupListSuccess: (state, action) => {
      state.groups = action.payload;
    },
    getMarcoPoloListSuccess: (state, action) => {
      state.marcoPolo = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getGroupDetailsSuccess: (state, action) => {
      state.groupDetails = action.payload;
    },
    getMarcoPoloMessageSuccess: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const {
  getGroupListSuccess,
  refetch,
  getGroupDetailsSuccess,
  getMarcoPoloListSuccess,
  getMarcoPoloMessageSuccess,
} = groupSlice.actions;

export const getAllGroups =
  (setIsLoading, videoConnect) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/admin/get/group/list/v1?videoConnect=${videoConnect}`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      dispatch(getGroupListSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

export const getAllMarcoPolos = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/marcopolo/list/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getMarcoPoloListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getGroupDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/group/get/member/list/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getGroupDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export const createGroup =
  (
    formData,
    setLoading,
    setModalShow,
    setFormData,
    setSelectedUsers,
    setSelectedUserNames,
    setId,
    setSearchQuery,
    setAddMemberDropdown
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const file = formData.image;
      if (file) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/upload/group/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("vctoken"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        formData.groupImage = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/create/group/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setModalShow(false);
      setSelectedUsers([]);
      setSelectedUserNames([]);
      setAddMemberDropdown(false);
      setSearchQuery("");
      setId("");
      setFormData({
        image: null,
        groupName: "",
        description: "",
        members: [],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.data.response.message, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const updateGroup =
  (
    id,
    formData,
    setLoading,
    setUpdateModalShow,
    setFormData,
    setSelectedUsers,
    setSelectedUserNames,
    setId,
    setSearchQuery,
    setAddMemberDropdown
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      if (typeof formData.image !== "string") {
        const file = formData.image ? formData.image : undefined;
        if (file) {
          const urlPre = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/upload/group/image/v1`;
          const responsePre = await axios.post(
            urlPre,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("vctoken"),
              },
            }
          );

          const { sUrl, sPath } = responsePre.data.data;
          formData.groupImage = sPath;

          axios.put(sUrl, file, {
            headers: {
              "Content-Type": file.sContentType,
            },
          });
        }
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/update/group/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setId("");
      setUpdateModalShow(false);
      setSelectedUsers([]);
      setSelectedUserNames([]);
      setAddMemberDropdown(false);
      setSearchQuery("");
      setFormData({
        image: null,
        groupName: "",
        description: "",
        members: [],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.data.response.message, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const deleteGroup =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/delete/group/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const removeMember =
  (id, memberId, setIsLoading, setRemoveModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/remove/member/${id}/v1`;
      const { data } = await axios.put(
        url,
        { memberId },
        {
          headers: { Authorization: localStorage.getItem("vctoken") },
        }
      );
      setIsLoading(false);
      setRemoveModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setRemoveModal(false);
    }
  };

export const addGroupMember =
  (id, formData, setIsLoading, setModalShow, setAddMemberDropdown) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/add/member/${id}/v1`;
      const { data } = await axios.put(url, formData, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setModalShow(false);
      setAddMemberDropdown(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setModalShow(false);
      setAddMemberDropdown(false);
    }
  };

export const getAllMarcoPoloMessages =
  (id, marcoPoloId, setIsLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/marcopolo/messages/${id}/${marcoPoloId}/v1`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      dispatch(getMarcoPoloMessageSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

export const restoreChat =
  (id, setIsLoading, setRestoreModal) => async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/restore/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: localStorage.getItem("vctoken") },
        }
      );
      dispatch(refetch(true));
      setIsLoading(false);
      setRestoreModal(false);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setRestoreModal(false);
    }
  };

export const permanentlyDeletedChat =
  (id, setIsLoading, setDeletePermanentModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/delete/permanent/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeletePermanentModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setDeletePermanentModal(false);
    }
  };

export const removeMultipleGroupChats =
  (selectedIds, setIsLoading) => async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/chatmodel/remove/multiple/groupchat/v1`;

      const { data } = await axios.put(
        url,
        { selectedIds },
        {
          headers: {
            Authorization: localStorage.getItem("vctoken"),
          },
        }
      );
      setIsLoading(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      setIsLoading(false);
      toast.error(error.data.response.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

export default groupSlice.reducer;
