import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const announcementSlice = createSlice({
  name: "announcement",
  initialState: {
    announcements: [],
    announcementDetails: {},
    refetch: false,
  },
  reducers: {
    getAnnouncementListSuccess: (state, action) => {
      state.announcements = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getAnnouncementDetailsSuccess: (state, action) => {
      state.announcementDetails = action.payload;
    },
  },
});

export const {
  getAnnouncementListSuccess,
  refetch,
  getAnnouncementDetailsSuccess,
} = announcementSlice.actions;

export const getAllAnnouncements = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAnnouncementListSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching announcements:", error);
    setIsLoading(false);
  }
};

export const getAnnouncementDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("vctoken"),
      },
    });
    dispatch(getAnnouncementDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.error("Error fetching announcement details:", error);
    setLoading(false);
  }
};

export const deleteAnnouncement =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("vctoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const createAnnouncement =
  (formData, setIsLoading, setModalShow, setFormData) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const file = formData.image;
      if (file) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/announcement/upload/announcement/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file.name,
            sContentType: file.type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("vctoken"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        formData.announcementImage = sPath;

        axios.put(sUrl, file, {
          headers: {
            "Content-Type": file.sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/create/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setIsLoading(false);
      setModalShow(false);
      setFormData({
        image: null,
        title: "",
        description: "",
        userType: ["all"],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {}
  };

export const updateAnnouncement =
  (id, formData, setLoading, setModalShow, setFormData, setForUpdate, setId) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      if (typeof formData.image !== "string") {
        const file = formData.image ? formData.image : undefined;
        if (file) {
          const urlPre = `${process.env.REACT_APP_API_BASE_URL}/announcement/upload/announcement/image/v1`;
          const responsePre = await axios.post(
            urlPre,
            {
              sFileName: file.name,
              sContentType: file.type,
            },
            {
              headers: {
                Authorization: localStorage.getItem("vctoken"),
              },
            }
          );

          const { sUrl, sPath } = responsePre.data.data;
          formData.announcementImage = sPath;

          axios.put(sUrl, file, {
            headers: {
              "Content-Type": file.sContentType,
            },
          });
        }
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/update/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("vctoken"),
        },
      });
      setLoading(false);
      setModalShow(false);
      setForUpdate(false);
      setId("");
      setFormData({
        image: null,
        title: "",
        description: "",
        userType: ["all"],
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {}
  };

export const changeAnnouncementStatus =
  (id, setIsLoading, setModalShow) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/update/status/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("vctoken"),
          },
        }
      );
      dispatch(refetch(true));
      setIsLoading(false);
      setModalShow(false);
    } catch (error) {
      setIsLoading(false);
      setModalShow(false);
    }
  };

  export const removeMultipleAnnouncements =
  (selectedIds, setIsLoading) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/announcement/remove/multiple/announcement/v1`;

      const { data } = await axios.put(
        url,
        { selectedIds },
        {
          headers: {
            Authorization: localStorage.getItem("vctoken"),
          },
        }
      );
      setIsLoading(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 3000,
      });
    } catch (error) {
      setIsLoading(false);
      toast.error(error.data.response.message, {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

export default announcementSlice.reducer;
